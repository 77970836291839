import React, { useContext, useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import { Form, Input, Menu, Checkbox } from "antd";
import DeliveryHomeForm from "../../../forms/DeliveryHomeForm";
import DeliveryHomeList from "../../../components/DeliveryHome/DeliveryHomeList";
import { getAddresses } from "../../../helpers/API/requests/address";
import { AuthContext } from "../../../providers/AuthProvider";
import { TAddress } from "../../../helpers/API/requests/interfaces/address";
import Spinner from "../../../components/Spinner";
import { LocaleConsumer } from "../../../providers/LocaleProvider";
import Button from "../../../components/Button";
import DeliveryShowroomSelection from "../../../components/DeliveryShowroomSelection";
import { TUser } from "../../../providers/AuthProvider/interfaces";
import { setOrder } from "../../../helpers/API/requests/order";
import { CartContext } from "../../../providers/CartProvider";
import { useHistory } from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import parseOrderErrorRedirect from "../../../helpers/parseOrderErrorRedirect";
import HTMLRender from "../../../components/HTMLRender";
import InputText from "../../../components/InputText";
import { ShowroomsContext } from "../../../providers/ShowroomProvider";
import { EShippingAddressType } from "../../../helpers/API/requests/interfaces/order";
import useDidUpdate from "../../../hooks/useDidUpdate";
import InputSelect from "../../../components/InputSelect";
import BottomMenuLayout from "../../../layouts/BottomMenuLayout";
import { TShowroom } from "../../../helpers/API/requests/interfaces/showrooms";
import { debounce } from "../../../helpers/debounce";

enum EDeliveryLocation {
  SHOWROOM = "showroom",
  DELIVERY = "delivery",
}

const DeliveryScreen: React.FC = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const showroomsContext = useContext(ShowroomsContext);
  const history = useHistory();

  const isRep = authContext.user
    ? !!authContext.user.isManager || !!authContext.user.isRep || false
    : false;

  const [mode, setMode] = useState<"favorite" | "custom">("favorite");
  const [addresses, setAddresses] = useState<TAddress[] | undefined>(undefined);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [showroom, setShowroom] = useState(authContext.user?.showroom);
  const [shippingContactName, setShippingContactName] = useState<string>("");
  const [email, setEmail] = useState(isRep ? "" : authContext.user!.username!);
  const [extraInfo, setExtraInfo] = useState<string>("");
  const [selectedFavAddress, setSelectedFavAddress] = useState<
    TAddress | undefined
  >(undefined);
  const [selectedShowroom, setSelectedShowroom] = useState<
    TShowroom | undefined
  >(undefined);
  const [deliveryLocation, setDeliveryLocation] = React.useState(
    /*isRep ? EDeliveryLocation.DELIVERY : EDeliveryLocation.SHOWROOM*/ ""
  );

  useEffect(() => {
    if (mode === "favorite") {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, form]);

  useEffect(() => {
    setShowroom(selectedShowroom?.showroomCode);
  }, [selectedShowroom]);

  useEffect(() => {
    if (deliveryLocation === EDeliveryLocation.SHOWROOM) {
      setSelectedFavAddress(undefined);
      // const firstShowroom = showroomsContext.showrooms.filter(e => e.subsidiaryCode === authContext.user?.client?.pierreFreySubsidiary);
      const firstShowroom = showroomsContext.showrooms.filter(
        (e) => e.showroomCode === authContext.user?.showroom
      );
      if (firstShowroom.length > 0) {
        setSelectedShowroom(firstShowroom[0]);
      }
    } else if (selectedFavAddress === undefined) {
      setSelectedFavAddress(addresses?.[0]);
      setSelectedShowroom(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryLocation]);

  useEffect(() => {
    getAddresses(authContext.user?.client?.accountCode!).then((data) => {
      setAddresses(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    if (showroom && deliveryLocation !== EDeliveryLocation.SHOWROOM) {
      setDeliveryLocation(EDeliveryLocation.SHOWROOM);
    }
  }, [showroom]);

  const submitOrder = debounce(
    (
      address: TAddress,
      user: TUser,
      shippingContact: string,
      billingName?: string,
      phone?: string
    ) => {
      const currentShowroom =
        showroomsContext.showrooms.find((s) => s.showroomCode === showroom) ||
        showroomsContext.showrooms.find(
          (s) => s.showroomCode === authContext.user?.showroom
        );

      setOrder(
        cartContext.items,
        address,
        {
          ...user,
          client: {
            ...user.client!,
            email: [email],
          },
        },
        shippingContact,
        deliveryLocation === EDeliveryLocation.SHOWROOM
          ? EShippingAddressType.SHOWROOM
          : mode === "favorite"
          ? EShippingAddressType.CLIENT_LIST
          : EShippingAddressType.ADDRESS,
        billingName,
        phone,
        currentShowroom,
        extraInfo
      )
        .then(() => {
          cartContext.empty();
          history.push(
            ERoutes.OrderSuccess +
              "?type=" +
              (showroom !== "delivery" ? "showroom" : "home")
          );
        })
        .catch((error) => {
          if (parseOrderErrorRedirect(error, history)) {
            cartContext.empty();
          }
        });
    }
  );

  const submitFavoriteAddress = () => {
    if (mode === "favorite") {
      const client = authContext.user?.client;
      const selectAddress =
        selectedFavAddress ||
        (deliveryLocation === EDeliveryLocation.SHOWROOM && addresses?.[0]) ||
        (client &&
          ({
            clientCode: client.clientServiceCode,
            address1: client.street,
            address2: client.streetAddition,
            postalCode: client.postalCode,
            city: client.city,
            stateOrDepartment: client.stateOrDepartment,
            countryCode: client.countryCode,
            countryName: client.countryCodeName,
          } as TAddress));

      if (selectAddress) {
        submitOrder(
          selectAddress,
          authContext.user!,
          shippingContactName,
          authContext.user?.client?.accountName
        );
      }
    }
  };

  const onValidate = () => {
    if (mode !== "favorite") {
      form.submit();
    }
    form2.submit();
  };

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <div className={Styles["delivery"]}>
          <div className={Styles["delivery__titleLine"]} />
          <h1 className={`title ${Styles["delivery__title"]}`}>
            <HTMLRender>
              {getTranslation(
                isRep ? "order_page.rep.title" : "order_page.title"
              )}
            </HTMLRender>
          </h1>
          <div className={Styles["delivery__container"]}>
            <div className={Styles["delivery__section"]}>
              <div>
                <div>
                  <Checkbox
                    className={Styles["delivery__section--checkbox"]}
                    checked={deliveryLocation === EDeliveryLocation.SHOWROOM}
                    onChange={() =>
                      setDeliveryLocation(EDeliveryLocation.SHOWROOM)
                    }
                  />
                </div>
                <div className={Styles["delivery__section--selection"]}>
                  <p className={Styles["delivery_info"]}>
                    {getTranslation("order_page.showroom_pickup")}
                  </p>
                </div>
              </div>
              <div className={Styles["delivery_or--container"]}>
                <div className={Styles["delivery__titleLine"]}></div>
                <div className={Styles["delivery_or"]}>
                  <p>{getTranslation("order_page.delivery_or")}</p>
                </div>
              </div>
              <div>
                <div>
                  <Checkbox
                    className={Styles["delivery__section--checkbox"]}
                    checked={deliveryLocation === EDeliveryLocation.DELIVERY}
                    onChange={() =>
                      setDeliveryLocation(EDeliveryLocation.DELIVERY)
                    }
                  />
                </div>
                <div className={Styles["delivery__section--selection"]}>
                  <p className={Styles["delivery_info"]}>
                    {getTranslation("order_page.delivery_info")}
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles["delivery__separator"]}>
              <div className={Styles["delivery__titleLine"]} />
            </div>
            <div
              style={{
                display:
                  deliveryLocation === EDeliveryLocation.DELIVERY
                    ? "block"
                    : "none",
              }}
            >
              <div>
                <p>{getTranslation("order_page.menuTitle.delivery")}</p>
              </div>
              <Menu
                className={Styles["delivery__menu"]}
                onClick={(e) => setMode(e.key as "favorite" | "custom")}
                selectedKeys={[mode]}
                mode="horizontal"
                overflowedIndicator={false}
              >
                <Menu.Item key="favorite">
                  {getTranslation("order_page.favorite")}
                </Menu.Item>
                <Menu.Item key="custom">
                  {getTranslation("order_page.custom")}
                </Menu.Item>
              </Menu>
              {mode === "favorite" ? (
                <>
                  {addresses ? (
                    <DeliveryHomeList
                      addresses={addresses}
                      setSelectedAddress={setSelectedFavAddress}
                      selectedAddress={selectedFavAddress}
                    />
                  ) : (
                    <Spinner />
                  )}
                </>
              ) : (
                <DeliveryHomeForm
                  formHook={form}
                  onSubmit={submitOrder}
                  onFieldsChange={() =>
                    setDeliveryLocation(EDeliveryLocation.DELIVERY)
                  }
                />
              )}
            </div>

            <div
              style={{
                display:
                  deliveryLocation === EDeliveryLocation.SHOWROOM
                    ? "block"
                    : "none",
              }}
            >
              <div>
                <p>{getTranslation("order_page.menuTitle.showroom")}</p>
              </div>
              <DeliveryShowroomSelection
                setSelectedShowroom={setSelectedShowroom}
                selectedShowroom={selectedShowroom}
              />
            </div>

            <Form form={form2} onFinish={submitFavoriteAddress} action="">
              {mode === "favorite" ? (
                <InputSelect
                  className={Styles["delivery__email"]}
                  label={getTranslation("order_page.form.email")}
                  fieldName="email"
                  value={email}
                  setValue={setEmail}
                  readOnly={!isRep}
                  options={[
                    {
                      label: getTranslation("order_page.form.select_an_email"),
                      value: "",
                    },
                    ...(authContext.user?.client?.email?./*.filter(_email => _email !== authContext.user!.username).*/ map(
                      (_email) => ({ label: _email, value: _email })
                    ) || []),
                  ]}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                      validateTrigger: "onSubmit",
                    },
                    {
                      type: "email",
                      message: getTranslation("order_page.form.invalid_email"),
                      validateTrigger: "onBlur",
                    },
                    {
                      max: 60,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 60 },
                      }),
                    },
                  ]}
                />
              ) : (
                <InputText
                  label={getTranslation("order_page.form.email")}
                  className={Styles["delivery__email"]}
                  fieldName="email"
                  value={email}
                  setValue={setEmail}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                      validateTrigger: "onSubmit",
                    },
                    {
                      type: "email",
                      message: getTranslation("order_page.form.invalid_email"),
                      validateTrigger: "onBlur",
                    },
                    {
                      max: 60,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 60 },
                      }),
                    },
                  ]}
                />
              )}

              {(EDeliveryLocation.SHOWROOM === deliveryLocation ||
                mode === "favorite") && (
                <InputText
                  label={getTranslation(
                    "order_page.shipping_contact_name.label"
                  )}
                  help={getTranslation("order_page.shipping_contact_name.help")}
                  className="mb-6"
                  fieldName={"shippingContactName"}
                  value={shippingContactName}
                  setValue={setShippingContactName}
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "order_page.form.mandatory_field"
                      ),
                    },
                    {
                      max: 36,
                      message: getTranslation("order_page.form.max_length", {
                        params: { max: 36 },
                      }),
                    },
                  ]}
                />
              )}
              <Input.TextArea
                placeholder={getTranslation("order_page.showroom_input")}
                maxLength={360}
                onChange={(e) => setExtraInfo(e.target.value)}
                rows={3}
              />
            </Form>
            {/*{(mode !== "favorite" || addresses?.length !== 0) &&
                        <Button
                            type="button"
                            styleType="plain"
                            size="large"
                            label={getTranslation("order_page.submit")}
                            onClick={onValidate}
                            className={Styles["delivery__submit"]}
                            disabled={deliveryLocation != EDeliveryLocation.SHOWROOM
                                && (deliveryLocation != EDeliveryLocation.DELIVERY
                                    || (selectedFavAddress === undefined && mode === "favorite")
                                )}
                        />
                        }*/}
          </div>
          <BottomMenuLayout>
            <Button
              type="button"
              styleType="plain"
              size="large"
              label={getTranslation("order_page.submit")}
              onClick={onValidate}
              className={Styles["delivery__submit"]}
              disabled={
                (deliveryLocation !== EDeliveryLocation.SHOWROOM ||
                  selectedShowroom === undefined) &&
                (deliveryLocation !== EDeliveryLocation.DELIVERY ||
                  (selectedFavAddress === undefined && mode === "favorite"))
              }
            />
            <Button
              className={Styles["bottomMenu__button"]}
              styleType="text"
              size="large"
              type="button"
              link={ERoutes.Scanner}
              label={getTranslation("order_page.back")}
            />
          </BottomMenuLayout>
        </div>
      )}
    </LocaleConsumer>
  );
};

export default DeliveryScreen;
